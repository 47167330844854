
import { ref, defineComponent } from 'vue'
import { BookmarkAdd as AddIcon } from '@vicons/carbon'
import * as api from '../../utils/api'
import { usePremium } from '../../utils/const'
import Menu from '../../components/menu/Hospital.vue'
import Hospital from '../../components/Hospital.vue'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
		Hospital,
		AddIcon,
	},
	data() {
		const refProp = {
			query: '',
			showHidden: false,
		}
		const modelRef = ref(refProp)
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		const confirmDialog = (title: string, content: string) => {
			return new Promise<boolean>((resolve, reject) => {
				dialog.warning({
					title,
					content,
					positiveText: 'OK',
					negativeText: 'キャンセル',
					maskClosable: false,
					closable: false,
					onNegativeClick: () => {
						reject(true)
					},
					onPositiveClick: () => {
						resolve(true)
					},
				})
			})
		}
		return {
			usePremium,
			applys: [],
			errorDialog,
			confirmDialog,
			model: modelRef,
			loading: true,
			totalLoading: true,
		}
	},
	mounted: function () {
		document.title = '応募一覧 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				const data = await api.get(`/v1/hospital/applys`)
				this.applys = data
				this.loading = false
				this.totalLoading = false
			} catch (e: any) {
				this.loading = false
				this.totalLoading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		addNew: function () {
			this.$router.push('/hospital/apply_new')
		},
		getDate: function (unix: number) {
			return moment(new Date(unix * 1000)).format('YYYY年MM月DD日')
		},
		goApplyEdit: function (id: string, status: string) {
			if (status === 'finished') return this.errorDialog({ message: '終了済みの応募は編集できません' }, () => true)
			this.$router.push(`/hospital/apply_edit/${id}`)
		},
		lookCheck: function (id: string) {
			window.open(`/apply/${id}`, '_blank')
		},
		copyNew: function (id: string) {
			this.$router.push(`/hospital/apply_copy/${id}`)
		},
		label: function (label: string) {
			if (label == 'draft') return '下書き'
			if (label == 'waitReview') return '審査待ち'
			if (label == 'public') return '公開中'
			if (label == 'finished') return '終了済み'
		},
		labelColor: function (label: string) {
			if (label == 'draft') return 'gray'
			if (label == 'waitReview') return 'orange'
			if (label == 'public') return 'green'
			if (label == 'finished') return 'blue'
		},
		finish: async function (id: string) {
			try {
				if (!await this.confirmDialog('応募を終了しますか？', '再開することはできません。また、進行中のやり取りがある場合は終了できません。')) return false
				const data = await api.post(`/v1/hospital/apply/finish/${id}`, {})
				this.init()
			} catch (e: any) {
				if(e.message) this.errorDialog(e, () => true)
			}
		},
		archive: async function (id: string) {
			try {
				if (!await this.confirmDialog('応募をやり取り停止しますか？', '再開することはできません。')) return false
				const data = await api.post(`/v1/hospital/apply/archive`, [id])
				this.init()
			} catch (e: any) {
				if(e.message) this.errorDialog(e, () => true)
			}
		},
	},
})
